var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"pt-0",attrs:{"no-header":""}},[_c('validation-observer',{ref:"CodeReviewVulnAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.addCodeReviewVulnerability($event)}}},[_c('b-form-group',{attrs:{"label":"Asset Name","label-for":"Asset Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Asset Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Asset Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","options":_vm.asset_options,"placeholder":"--Search Asset--","autocomplete":"","reduce":function (asset_id) { return asset_id.value; }},on:{"search":_vm.fetchOptions},model:{value:(_vm.form.asset_id),callback:function ($$v) {_vm.$set(_vm.form, "asset_id", $$v)},expression:"form.asset_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"VM Name","label-for":"vm_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("VM Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"vm_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vm_name","state":errors.length > 0 ? false : null,"name":"vm_name"},model:{value:(_vm.form.vm_name),callback:function ($$v) {_vm.$set(_vm.form, "vm_name", $$v)},expression:"form.vm_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Query Path","label-for":"Query Path"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Query Path")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Query Path","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Query_Path","state":errors.length > 0 ? false : null,"name":"Query_Path"},model:{value:(_vm.form.query_path),callback:function ($$v) {_vm.$set(_vm.form, "query_path", $$v)},expression:"form.query_path"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Source Name","label-for":"Source Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Source Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Source Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"src_name","state":errors.length > 0 ? false : null,"name":"src_name"},model:{value:(_vm.form.src_name),callback:function ($$v) {_vm.$set(_vm.form, "src_name", $$v)},expression:"form.src_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Source File Name","label-for":"Source File Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Source File Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Source File Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"src_file_name","state":errors.length > 0 ? false : null,"name":"src_file_name"},model:{value:(_vm.form.src_file_name),callback:function ($$v) {_vm.$set(_vm.form, "src_file_name", $$v)},expression:"form.src_file_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Source Line","label-for":"Source Line"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Source Line")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Source Line","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"src_line","state":errors.length > 0 ? false : null,"name":"src_line","type":"number"},model:{value:(_vm.form.src_line),callback:function ($$v) {_vm.$set(_vm.form, "src_line", $$v)},expression:"form.src_line"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Source Column","label-for":"Source Column"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Source Column")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Source Column","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"src_column","state":errors.length > 0 ? false : null,"name":"src_column","type":"number"},model:{value:(_vm.form.src_column),callback:function ($$v) {_vm.$set(_vm.form, "src_column", $$v)},expression:"form.src_column"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Source Node Id","label-for":"Source Node Id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Source Node Id")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Source Node Id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"src_node_id","state":errors.length > 0 ? false : null,"name":"src_node_id","type":"number"},model:{value:(_vm.form.src_node_id),callback:function ($$v) {_vm.$set(_vm.form, "src_node_id", $$v)},expression:"form.src_node_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Destination Name","label-for":"Destination Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Destination Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Destination Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dest_name","state":errors.length > 0 ? false : null,"name":"dest_name"},model:{value:(_vm.form.dest_name),callback:function ($$v) {_vm.$set(_vm.form, "dest_name", $$v)},expression:"form.dest_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Destination File Name","label-for":"Destination File Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Destination File Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Destination File Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dest_file_name","state":errors.length > 0 ? false : null,"name":"dest_file_name"},model:{value:(_vm.form.dest_file_name),callback:function ($$v) {_vm.$set(_vm.form, "dest_file_name", $$v)},expression:"form.dest_file_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Destination Line","label-for":"Destination Line"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Destination Line")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Destination Line","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dest_line","state":errors.length > 0 ? false : null,"name":"dest_line","type":"number"},model:{value:(_vm.form.dest_line),callback:function ($$v) {_vm.$set(_vm.form, "dest_line", $$v)},expression:"form.dest_line"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Destination Column","label-for":"Destination Column"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Destination Column")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Destination Column","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dest_column","state":errors.length > 0 ? false : null,"name":"dest_column","type":"number"},model:{value:(_vm.form.dest_column),callback:function ($$v) {_vm.$set(_vm.form, "dest_column", $$v)},expression:"form.dest_column"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Destination Node Id","label-for":"Destination Node Id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Destination Node Id")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Destination Node Id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dest_node_id","state":errors.length > 0 ? false : null,"name":"dest_node_id","type":"number"},model:{value:(_vm.form.dest_node_id),callback:function ($$v) {_vm.$set(_vm.form, "dest_node_id", $$v)},expression:"form.dest_node_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Severity","label-for":"Severity"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Severity")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"severity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.severity_options,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.severity),callback:function ($$v) {_vm.$set(_vm.form, "severity", $$v)},expression:"form.severity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"OWASP Category","label-for":"owasp_category"}},[_c('v-select',{staticClass:"pb-1",attrs:{"multiple":"","label":"title","options":_vm.owasp_options,"autocomplete":""},on:{"search":_vm.fetchOwaspOptions},model:{value:(_vm.form.owasp_categories),callback:function ($$v) {_vm.$set(_vm.form, "owasp_categories", $$v)},expression:"form.owasp_categories"}})],1)],1),_vm._v(" "+_vm._s(_vm.owasp_categories)+" ")],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","size":"sm","disabled":invalid}},[_vm._v(" Add ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }