<template>
  <b-card no-header class="pt-0">
    <validation-observer ref="CodeReviewVulnAddForm" #default="{ invalid }">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="addCodeReviewVulnerability"
      >
        <b-form-group label="Asset Name" label-for="Asset Name">
          <template #label>
            <span>Asset Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Asset Name"
            rules="required"
          >
            <v-select
              v-model="form.asset_id"
              label="text"
              :options="asset_options"
              placeholder="--Search Asset--"
              @search="fetchOptions"
              autocomplete
              :reduce="(asset_id) => asset_id.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Name" label-for="Name">
          <template #label>
            <span>Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="VM Name" label-for="vm_name">
          <template #label>
            <span>VM Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="vm_name"
            rules="required"
          >
            <b-form-input
              id="vm_name"
              v-model="form.vm_name"
              :state="errors.length > 0 ? false : null"
              name="vm_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Query Path" label-for="Query Path">
          <template #label>
            <span>Query Path</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Query Path"
            rules="required"
          >
            <b-form-input
              id="Query_Path"
              v-model="form.query_path"
              :state="errors.length > 0 ? false : null"
              name="Query_Path"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group label="Source Name" label-for="Source Name">
              <template #label>
                <span>Source Name</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Source Name"
                rules="required"
              >
                <b-form-input
                  id="src_name"
                  v-model="form.src_name"
                  :state="errors.length > 0 ? false : null"
                  name="src_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Source File Name" label-for="Source File Name">
              <template #label>
                <span>Source File Name</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Source File Name"
                rules="required"
              >
                <b-form-input
                  id="src_file_name"
                  v-model="form.src_file_name"
                  :state="errors.length > 0 ? false : null"
                  name="src_file_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Source Line" label-for="Source Line">
              <template #label>
                <span>Source Line</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Source Line"
                rules="required"
              >
                <b-form-input
                  id="src_line"
                  v-model="form.src_line"
                  :state="errors.length > 0 ? false : null"
                  name="src_line"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Source Column" label-for="Source Column">
              <template #label>
                <span>Source Column</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Source Column"
                rules="required"
              >
                <b-form-input
                  id="src_column"
                  v-model="form.src_column"
                  :state="errors.length > 0 ? false : null"
                  name="src_column"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Source Node Id" label-for="Source Node Id">
              <template #label>
                <span>Source Node Id</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Source Node Id"
                rules="required"
              >
                <b-form-input
                  id="src_node_id"
                  v-model="form.src_node_id"
                  :state="errors.length > 0 ? false : null"
                  name="src_node_id"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Destination Name" label-for="Destination Name">
              <template #label>
                <span>Destination Name</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Destination Name"
                rules="required"
              >
                <b-form-input
                  id="dest_name"
                  v-model="form.dest_name"
                  :state="errors.length > 0 ? false : null"
                  name="dest_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Destination File Name"
              label-for="Destination File Name"
            >
              <template #label>
                <span>Destination File Name</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Destination File Name"
                rules="required"
              >
                <b-form-input
                  id="dest_file_name"
                  v-model="form.dest_file_name"
                  :state="errors.length > 0 ? false : null"
                  name="dest_file_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Destination Line" label-for="Destination Line">
              <template #label>
                <span>Destination Line</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Destination Line"
                rules="required"
              >
                <b-form-input
                  id="dest_line"
                  v-model="form.dest_line"
                  :state="errors.length > 0 ? false : null"
                  name="dest_line"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Destination Column"
              label-for="Destination Column"
            >
              <template #label>
                <span>Destination Column</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Destination Column"
                rules="required"
              >
                <b-form-input
                  id="dest_column"
                  v-model="form.dest_column"
                  :state="errors.length > 0 ? false : null"
                  name="dest_column"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Destination Node Id"
              label-for="Destination Node Id"
            >
              <template #label>
                <span>Destination Node Id</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="Destination Node Id"
                rules="required"
              >
                <b-form-input
                  id="dest_node_id"
                  v-model="form.dest_node_id"
                  :state="errors.length > 0 ? false : null"
                  name="dest_node_id"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Severity" label-for="Severity">
              <template #label>
                <span>Severity</span>
                <span
                  class="text-danger font-weight-bold h5"
                  style="margin-left: 3px"
                  >*</span
                >
              </template>
              <validation-provider
                #default="{ errors }"
                name="severity"
                rules="required"
              >
                <b-form-select
                  v-model="form.severity"
                  :options="severity_options"
                  :state="errors.length > 0 ? false : null"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="OWASP Category" label-for="owasp_category">
              <v-select
                class="pb-1"
                v-model="form.owasp_categories"
                multiple
                label="title"
                :options="owasp_options"
                @search="fetchOwaspOptions"
                autocomplete
              />
            </b-form-group>
          </b-col>
          {{ owasp_categories }}
        </b-row>
        <b-button type="submit" variant="primary" size="sm" :disabled="invalid">
          Add
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
      owasp_options: [],
      asset_options: [],
      form: {
        asset_id: null,
        name: "",
        vm_name: "",
        query_path: "",
        src_file_name: "",
        src_line: null,
        src_column: null,
        src_node_id: null,
        src_name: "",
        dest_file_name: "",
        dest_line: null,
        dest_column: null,
        dest_node_id: null,
        dest_name: "",
        severity: null,
        owasp_categories: [],
      },
    };
  },

  created: function () {
    //  this.load(); --> not calling this due page load issues
  },
  methods: {
    load: function () {},

    addCodeReviewVulnerability: function () {
      this.$refs.CodeReviewVulnAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          var self = this;
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: this.form,
            url: process.env.VUE_APP_BASEURL + "code-review/vulns/",
          };
          this.$http(options).then((res) => {
            if (res.data.id) {
              this.$router.push({
                name: "Code-Review",
              });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added code review vulnerability",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }
          });
        }
      });
    },
    fetchOwaspOptions(search, loading) {
      if (search.length) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "master/owasp?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.owasp_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              id: value.id,
              title: value.title,
              // full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.owasp_options.push(a);
          });
        });
      }
    },
    fetchOptions(search) {
      if (search.length > 1) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_options.push(a);
          });
        });
      }
    },
  },
};
</script>
<style lang="scss">
[dir] .card-body {
  padding: 0 1.5rem 1.5rem 1.5rem !important;
}
@import "vue-select/src/scss/vue-select.scss";
</style>